import {redirectNextTraining, trainingInitialWindowSetting, imageSizing, waitImageLoadedForAfterAjax, virticalSpaceFitting, fontSizeFittingByWidth} from './training_common'
import shortTimer from './memories/short_timer';
import no6Sentense from './memories/no6_sentense';
import no8_no9Matrix from './memories/no8_no9_matrix';
import no3Ball from './memories/no3_ball';
import no4Calc from './memories/no4_calc';
import no5Whole from './memories/no5_whole';
import {waitStartButtonActive} from './memories/memory_common';


let isPerfect = 1 ; // 0/1: 不正解あり/全正解 

$(document).on('turbolinks:load', function(){

    if( $('#exercise-type').val() == "Memory"){

        const patternNo = $('#exercise-pattern_no').val();
        const previewMode = $('#preview-mode').val();
        const redirectUrl = $("#redirect-url").val();
        const workId = $("#work-id").val();
        const workLevel = $("#work-level").val();
        let loopInfo = JSON.parse($("#loop-info").val());
        const loopTotalCount = loopInfo.total ;
        let loopCounter = loopInfo.count;

        trainingInitialWindowSetting();

        if ( patternNo == "1"
        || patternNo == "2"
        || patternNo == "7"){

            structureFitting(patternNo);
            shortTimer();
            if(patternNo == "1"){
                // 低速回線対策：画像ロード完了後に開始ボタンをアクティブ化
                waitStartButtonActive($(".c-modal .target img"));
            }
        }

        if (patternNo == "3" || patternNo == "10"){
            
            $(window).on('load', function(){ 
            
                // モーダルの表示
                $(".c-modal").removeClass("c-hidden");
            
                $(".memory-no3-table td").height( $(".height-80").height()/5);
            
                /*
                console.log("現実のtableの縦横");
                console.log($(".memory-no3-table").width(), $(".memory-no3-table").height(), $(".memory-no3-table").width()/$(".memory-no3-table").height());

                console.log("tdの縦横");
                console.log($(".memory-no3-table td").width(), $(".memory-no3-table td").height(), $(".memory-no3-table td").width()/$(".memory-no3-table td").height());
                */
                const tableRatio = $(".memory-no3-table").width() / $(".height-80").height();

                // no3のボールの大きさ（直径）
                const fontsize = $(".memory-no3-table td").height() * 0.6;
                $(".memory-no3-table td").css("font-size", fontsize );

                // no10の画像の大きさ
                const imageWidth = $(".memory-no3-table td").width() * 0.7;
                //console.log(imageWidth, tableRatio);
            
                if(patternNo == "10"){
                    $(".memory-no3-table td img").each(function(){
                        imageSizing($(this), imageWidth, $(".height-80").height()/5 );
                    });
                    // 低速回線対策：画像ロード完了後に開始ボタンをアクティブ化
                    waitStartButtonActive($(".c-modal .memory-no3-table img"));
                }
                
                // モーダル非表示
                $(".c-modal").addClass("c-hidden");

                // bootstrap-selectの設定
                const $selectPicker = $(".selectpicker");
                $selectPicker.on('changed.bs.select', function(e){
                    // no3 選択された表示のフォントサイズ調整
                    let svg = $("div", $(e.target).next());
                    svg.css("font-size", fontsize); 
                    let googleIcon = $("span", $(e.target).next());
                    googleIcon.css("font-size", fontsize);
                    
                    // no10 選択された画像の大きさ調整
                    const imgObj = $("img", $(e.target).next());
                    imageSizing(imgObj, imageWidth, imageWidth / tableRatio);
                });

                $selectPicker.on('shown.bs.select', function(e){
                    // 選択肢のフォントサイズ調整(resizedメンバを新設し、最初の一回のみ調整)
                    if(e.target.resized === undefined){
                        e.target.resized = true;
        
                        const $dropdown = $(e.target).parent();
                    
                        $(".google-icon-font", $dropdown).css("font-size", fontsize);
                        $(".dropdown-menu", $dropdown).css("font-size", fontsize);
                
                
                        // no10の画像の選択肢
                        $(".dropdown-menu img", $dropdown).each(function(){
                            imageSizing($(this), imageWidth, imageWidth / tableRatio);
                        });
                    }
                });
                let count = 0;
                $selectPicker.on('loaded.bs.select', function(e){
                    //console.log("select loaded!");
                    count++;
                    const $dropdownToggleButton = $(e.target).next();
                    // 初期表示でセンタリング
                    $(".filter-option", $dropdownToggleButton).css("text-align", "center");
    
                    const $dropdown = $(e.target).parent();
                    // 選択肢のセンタリング
                    $(".dropdown-menu", $dropdown).css("text-align", "center");

                    if(count == $selectPicker.length){
                         // プルダウンを開いた時に、tdの高さが動くため、APIで動かしておく
                        $selectPicker.selectpicker('val', 1);
                        $selectPicker.selectpicker('val', -1);
                        // 初期選択表示のフォントサイズ
                        $(".google-icon-font", $selectPicker.next()).css("font-size", fontsize);

                    }
                });
            });
            no3Ball();
        }

        if (patternNo == "4"){

            let fontsize = 50;
            const $calcContentsTable = $(".calc-contents .memory-no3-table");
            const $tdCalcContentsTable = $("td:first", $calcContentsTable);
            while(1){
                $calcContentsTable.css("font-size", fontsize);
                //console.log($(".calc-contents .memory-no3-table td:first").height(), $(".calc-contents .memory-no3-table td:first").width());
                if($tdCalcContentsTable.height() < $tdCalcContentsTable.width()){
                    break;
                }
                fontsize -= 1;
                if(fontsize <= 0) break;
            }
            // 1024x700のとき、セレクトウィンドが下限を突破するため、0.8がけのフォントにする。
            fontsize *= 0.8;
            $calcContentsTable.css("font-size", fontsize);


            $(".memory-instruction-border").css("font-size", fontsize * 0.6);
            $("p.fontsize-base").css("font-size", fontsize);

            const $rightSideExample = $(".memory-no4-maru .memory-no3-table-answer");
            $rightSideExample.width( $tdCalcContentsTable.width() * 0.5 );
            $rightSideExample.height( $rightSideExample.width() );
            $rightSideExample.css("margin-right", 0).css("margin-left", 0);

            // bootstrap-selectの設定
            const $selectPicker = $(".selectpicker");
            $selectPicker.on('changed.bs.select', function(e){
                // 選択された表示のフォントサイズ調整
                let div = $("div", $(e.target).next());
                div.css("font-size", fontsize); 
                let googleIcon = $("span", $(e.target).next());
                googleIcon.css("font-size", fontsize);
            });
            $selectPicker.on('shown.bs.select', function(e){
                // 選択肢のフォントサイズ調整(resizedメンバを新設し、最初の一回のみ調整)
                if(e.target.resized === undefined){
                    e.target.resized = true;
    
                    const $dropdown = $(e.target).parent();
                
                    $(".google-icon-font", $dropdown).css("font-size", fontsize);
                    $(".dropdown-menu", $dropdown).css("font-size", fontsize);
                }
            });
            let count = 0;
            $selectPicker.on('loaded.bs.select', function(e){
                //console.log("select loaded!");
                const $dropdownToggleButton = $(e.target).next();
                // 初期表示でセンタリング
                $(".filter-option", $dropdownToggleButton).css("text-align", "center");

                const $dropdown = $(e.target).parent();
                // 選択肢のセンタリング
                $(".dropdown-menu", $dropdown).css("text-align", "center");
                
                count++;
                if(count == $selectPicker.length){
                    $selectPicker.selectpicker('val', "○");
                    $selectPicker.selectpicker('val', -1);
                    // 初期選択表示のフォントサイズ
                    $(".google-icon-font", $selectPicker.next()).css("font-size", fontsize);

                    // 設問館のスペースを調整
                    let spaceSize = 50;
                    spaceSize = virticalSpaceFitting($(".vertical-space"), $(".fb-main-contents-height").height() * 0.9, spaceSize, $(".calc-contents"));
                    /*
                    while(1){
                        $(".vertical-space").css("margin-bottom", spaceSize);
                        if( $(".calc-contents").height() < $(".fb-main-contents-height").height() * 0.8 ){
                            //console.log(spaceSize);
                            break;
                        }
                        spaceSize -= 1;
                    }
                    */
                    if(spaceSize <= 0){
                        $(".vertical-space").css("margin-bottom", 10);
                    }

                }
            });
            no4Calc(workLevel);
        }

        if (patternNo == "5"){

            // 回答文はdisplay: noneで表示済み
            // 記憶する文章を表示(モーダル表示)
            // モーダル表示(記憶する文)
            $(".c-modal").removeClass("c-hidden");
            
            //$("#no5-question").css("opacity", 0);

            
            // 記憶する文章のフォントサイズ設定
            let fontsize = 60;
            while(1){
                $("span.target").css("font-size", fontsize);
                let sizeOk = true;
                $("span.target").each(function(){
                    if( $(this).width() > $(".c-modal .col-10").width() ){
                        sizeOk = false;
                        return false;
                    }
                });
                if(sizeOk){
                    break;
                } 
                fontsize -= 1;
            }

            // 回答文に上記のフォントサイズを仮設定
            $("#no5-question").css("font-size", fontsize);

             // 回答文の上下スペースを調整
            let spaceSize = 50;
            spaceSize = virticalSpaceFitting($(".vertical-space"), $("#no5-question").parent().height() * 0.9, spaceSize, $("#no5-question"));
            
            // モーダル非表示(記憶する文)
            $(".c-modal").addClass("c-hidden");
            
            no5Whole(fontsize, workId);
        }

        if (patternNo == "6"){
            
            
            let fontsize = 70;
            // フォントサイズの設定
            // 最長の問題文がコンテンツ枠に収まるまでフォントサイズを小さくしながら調整
            while(1){
                $(".memory-no6-contents").css("font-size", fontsize);
                let sizeOk = true;
                $(".memory-no6-contents .checkbox-inline").each(function(){
                    if( $(this).width() > $(".memory-no6-contents .space").width() ){
                        sizeOk = false;
                        return false;
                    }
                });
                if(sizeOk){
                    //console.log("fontsize3: " + fontsize);
                    break;
                } 
                fontsize -= 1;
            }

            //　調整後のフォントサイズをさらに0.8倍して採用する
            fontsize *= 0.8;
            $(".memory-no6-contents").css("font-size", fontsize);
            $(".memory-instruction-border").css("font-size", fontsize);

            // 20250123
            // 採用したフォントサイズを使っても縦幅でコンテンツ枠をはみ出したら、フォントサイズを0.9倍していき、収まるまで調整する
            while(1){
                // outerHeight()はパディングを含めた高さ
                if($(".memory-no6-contents").parent().outerHeight() > $(".fb-main-contents-height").height()){
                    fontsize *= 0.9;
                    $(".memory-no6-contents").css("font-size", fontsize);
                    $(".memory-instruction-border").css("font-size", fontsize);
                    //alert("font adjust!");
                }
                else{
                    break;
                }
            }

            // 20250123
            // lowレベルの問題の場合、キーワードが、キーワード枠からはみ出ることがあるため、その場合はキーワードのフォントサイズをさらに調整する。
            if(workLevel == "low"){
                while(1){
                    let keyword_text = 0;
                    keyword_text += $(".keyword-part").width();
                    $(".keyword-space .sentense-part").each(function(){
                        keyword_text += $(this).width();
                    });
                    if($(".memory-instruction-border .keyword-space").width() < keyword_text){
                        fontsize *= 0.8;
                        $(".memory-instruction-border").css("font-size", fontsize);
                        //console.log("resize!");
                        //alert("font adjust!");
                    }
                    else{
                        break;
                    }
                }
            }
            
            if(workLevel != "low"){
                fontsize = fontSizeFittingByWidth($("p.extra-sentense"), $(".memory-instruction-border").width(), 1, fontsize);
                $(".memory-instruction-border").css("font-size", fontsize);
            }
            

            let spaceSize = 70;
            virticalSpaceFitting($(".vertical-space"), $(".fb-main-contents-height").height() * 0.9, spaceSize, $(".memory-no6-contents"));
            /*
            while(1){
                $(".vertical-space").css("margin-bottom", spaceSize);
                if( $(".memory-no6-contents").height() < $(".fb-main-contents-height").height() * 0.9 ){
                    //console.log(spaceSize);
                    break;
                }
                spaceSize -= 1;
            }
            */
            $(".memory-instruction-border").css("visibility", "visible");
            $(".memory-no6-contents").css("visibility", "visible");
            no6Sentense();
        }

        if (patternNo == "8"
        || patternNo == "9"){
            let fontsize = 40;
            while(1){
                $(".memory-no3-table").css("font-size", fontsize);
                if( $(".memory-no3-table").height() < $(".fb-main-contents-height").height() * 0.85){
                    //console.log("fontsize3: " + fontsize);
                    break;
                }
                fontsize -= 1;
            }
            $(".memory-instruction-border").css("font-size", fontsize);
            $(".memory-instruction-border .google-icon-font").css("font-size", fontsize);
            // const instructionWidth = $(".memory-instruction-border").width();
            // $(".memory-instruction-border").width(instructionWidth * 1.5);
            
            $(".memory-instruction-border").css("visibility", "visible");
            no8_no9Matrix();
        }

        // モーダルを非表示にした際に、ajax処理により、次の問題を描画する
        $("#trainingModal").on('hidden.bs.modal', function(){
            //if( $("#trainingModal .modal-title").html() == gon.modal_titles["合格"]) {
            if( $("#modal-title-key").val() == "合格") {
                if(loopCounter + 1 == loopTotalCount){
                    $.get("/save_training_history", {exercise_id: $('#exercise-id').val(), score: isPerfect}, redirectNextTraining);
                }
                else {
                    let workHistory = [];
                    if($("#current-work-id").length){
                        const currentWorkId = parseInt($("#current-work-id").val());
                        workHistory = JSON.parse($("#work-history").val());
                        workHistory.push(currentWorkId);
                        workHistory = $.uniqueSort(workHistory);
                        $("#work-history").val(JSON.stringify(workHistory));
                    }
                    // $.get("/ajax_memory_exercise", {/*target_count: gon.target_count,*/ work_ids: gon.work_ids/*, selection_count: gon.selection_count*/, count: gon.count}, function(data){
                    $.get("/ajax_memory_exercise", {work_id: workId, preview_mode: previewMode, pattern_no: patternNo, count: loopCounter + 1, work_level: workLevel, work_history: workHistory}, function(data){
                        
                        loopInfo = JSON.parse($("#loop-info").val());
                        loopCounter = loopInfo.count;

                        // 描画後に、スタートボタンを表示
                        //$("#memory-start").toggle();
                        $("#countdown").html("");
                        
                        // Ajax後の画像の読み込み待ち　//

                        // 読み込み画像のソースの配列を作成
                        const srcArry = [];
                        $(".choices img").each(function(){
                            srcArry.push($(this).attr("src"));
                        });
                        $(".target img").each(function(){
                            srcArry.push($(this).attr("src"));
                        });

                        if(patternNo == "1"){
                            waitImageLoadedForAfterAjax(srcArry, imageFitting);
                            waitStartButtonActive($(".c-modal .target img"));
                        }
                        else if(patternNo == "2"){
                            waitImageLoadedForAfterAjax(srcArry, figureFitting)
                        }
                        else if(patternNo == "7"){
                            fontFitting();
                            //waitImageLoadedForAfterAjax(srcArry, 
                        }
                        
                    });
                    
                }
            }
            else if( $("#modal-title-key").val() == "レベル変更") {
            // else if( $("#trainingModal .modal-title").html().indexOf("レベル") >= 0) {
                location.href = redirectUrl;
            }
            else if( $("#modal-title-key").val() != "ヒント表示"){
                isPerfect = 0;
            }
        });


    }
});

let imageAreaWidth;
let imageAreaHight;
function structureFitting(patternNo){
    $(window).on("load", function(){
        $(".choices").width( $(".memory-main-contents").width()/3 );
        // console.log("構造");
        // console.log($(".memory-selection").width(), $(".memory-selection").height());
        imageAreaWidth = $(".choices").width() * 0.9;
        imageAreaHight = $(".choices").height() * 0.8;
        if(patternNo == "1"){
            imageFitting();
        }
        else if(patternNo == "2"){
            figureFitting();
        }
        else if(patternNo == "7"){
            fontFitting();
        }

    });
}

function fontFitting(){
    $(".choices").width( $(".memory-main-contents").width()/3 );
    
    let fontsize = 180;
    while(1){
        $(".choices span").css("font-size", fontsize);
        if( $(".choices").height() * 0.9 > $(".choices span").height() ){
            //console.log("fontsize3: " + fontsize);
            break;
        }
        fontsize -= 1;
    }
    $(".target >").css("font-size", fontsize);
    

    $(".c-modal").removeClass("c-hidden");

    // モーダルを非表示
    $(".c-modal").addClass("c-hidden");
    // 選択肢も非表示
    $(".memory-main-contents").css("display", "none");
}


function figureFitting(){
    $(".choices").width( $(".memory-main-contents").width()/3 );
    //console.log(imageAreaHight);
    $(".c-modal").removeClass("c-hidden");
    $(".google-icon-font").css("font-size", imageAreaHight);

    // モーダルを非表示
    $(".c-modal").addClass("c-hidden");
    // 選択肢も非表示
    $(".memory-main-contents").css("display", "none");
}


function imageFitting(){
        $(".choices").width( $(".memory-main-contents").width()/3 );
    
        new Promise(function(resolve){
            // console.log("開始");
            // console.log(imageAreaWidth, imageAreaHight);
            
            // 画像が隠れているとサイズを取得できないため、モーダルを表示
            $(".c-modal").removeClass("c-hidden");


            const answerImages = [];
            $(".target img").each(function(){
                answerImages.push(this);        
            });
        
            $(".choices img").each(function(){
                //console.log("image fitting(前): " + $(this).width() + "x" + $(this).height());
                let image = this;
                
                    $(image).width( imageAreaWidth );
                    //console.log("image fitting(横幅のみ): " + $(image).width() + "x" + $(image).height());
                    if($(image).height() > imageAreaHight){
                        const ratio = $(image).width()/ $(image).height();
                        $(image).height( imageAreaHight );
                        $(image).width( imageAreaHight * ratio );
                        //console.log("image fitting(縦横の両方): " + $(image).width() + "x" + $(image).height());
                    }
                    $.each(answerImages, function(_i, answerImage){
                        if( $(answerImage).attr("src") == $(image).attr("src") ){
                            //console.log("見本画像の大きさが読み出せているか？: " + $(answerImage).width() + "x" + $(answerImage).height());
                            //console.log("真似る画像の大きさが読み出せているか？" + $(image).width() + 'x' + $(image).height());
                            $(answerImage).width($(image).width());
                            $(answerImage).height($(image).height());
                        }
                    });
            });
            resolve("done!");
        }).then(function(val){
            //console.log(val);
            // モーダルを非表示
            $(".c-modal").addClass("c-hidden");
            // 選択肢も非表示
            $(".memory-main-contents").css("display", "none");
        });
}
